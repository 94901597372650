import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class SecureHashTokenService {
    private readonly secretKey = 'votre_clé_secrète_très_longue_et_complexe';

    signToken(payload: any, expiresIn: number = 86400): string {
        const header = {
            alg: 'HS256',
            typ: 'JWT'
        };

        const now = Math.floor(Date.now() / 1000);
        const expirationTime = now + expiresIn;

        let data = {
            ...payload,
            iat: now,
            exp: expirationTime
        };

        if (typeof payload === 'string') {
            data = {
                payload: payload,
                iat: now,
                exp: expirationTime
            }
        }

        const encodedHeader = this.base64UrlEncode(JSON.stringify(header));
        const encodedPayload = this.base64UrlEncode(JSON.stringify(data));

        const token = `${encodedHeader}.${encodedPayload}`;
        const signature = this.generateSignature(token);

        return `${token}.${signature}`;
    }

    verifyAndDecodeToken(token: string): any | null {
        try {
            const [encodedHeader, encodedPayload, receivedSignature] = token.split('.');

            const calculatedSignature = this.generateSignature(`${encodedHeader}.${encodedPayload}`);
            if (receivedSignature !== calculatedSignature) {
                throw new Error('Signature invalide');
            }

            const payload = JSON.parse(this.base64UrlDecode(encodedPayload));

            if (payload.exp && payload.exp < Math.floor(Date.now() / 1000)) {
                throw new Error('Token expiré');
            }

            return payload;
        } catch (error) {
            console.error('Erreur lors de la vérification du token:', error);
            return null;
        }
    }

    private generateSignature(data: string): string {
        const hash = CryptoJS.HmacSHA256(data, this.secretKey);
        return this.base64UrlEncode(hash.toString(CryptoJS.enc.Base64));
    }

    private base64UrlEncode(input: string): string {
        const base64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(input));
        return base64.replace(/=+$/, '')
            .replace(/\+/g, '-')
            .replace(/\//g, '_');
    }

    private base64UrlDecode(input: string): string {
        let base64 = input.replace(/-/g, '+').replace(/_/g, '/');
        while (base64.length % 4) {
            base64 += '=';
        }
        return CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(base64));
    }
}
