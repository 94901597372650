import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {Store} from "@ngrx/store";
import {AuthState} from "../../state/reducers/auth.reducer";
import * as AuthActions from '../../state/actions/auth.actions';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenService {
    private readonly TOKEN_KEY = 'auth_token';
    private readonly LEGAL_ENTITY_KEY = 'legal_entity';
    private readonly REFRESH_TOKEN_KEY = 'refresh_token';

    constructor(
        private cookieService: CookieService,
        private store: Store<AuthState>
    ) {}

    setToken(token: string, refreshToken: string): void {
        //console.log('setToken', token, refreshToken);
        // Expiration du token après 15 minutes (15 * 60 * 1000 millisecondes)
        const tokenExpiryDate = new Date(new Date().getTime() + 15 * 60 * 1000);
        const refreshTokenExpiryDate = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000); // Expire dans 7 jours
        this.cookieService.set(this.TOKEN_KEY, token, tokenExpiryDate, '/', undefined, true, 'Strict');
        this.cookieService.set(this.REFRESH_TOKEN_KEY, refreshToken, refreshTokenExpiryDate, '/', undefined, true, 'Strict');
    }

    setLegalEntity(legalEntity: string): void {
        this.cookieService.set(this.LEGAL_ENTITY_KEY, legalEntity, new Date(new Date().getTime() + 15 * 60 * 1000), '/', undefined, true, 'Strict');
    }

    getLegalEntity(): string | null {
        return this.cookieService.get(this.LEGAL_ENTITY_KEY) || null;
    }

    getToken(): string | null {
        return this.cookieService.get(this.TOKEN_KEY) || null;
    }

    getRefreshToken(): string | null {
        return this.cookieService.get(this.REFRESH_TOKEN_KEY) || null;
    }

    goToLogout(): void {
        this.removeTokens();
        //this.store.dispatch(AuthActions.logout());
    }

    removeTokens(): void {
        this.cookieService.delete(this.TOKEN_KEY);
        this.cookieService.delete(this.REFRESH_TOKEN_KEY);
    }

    isTokenExpired(): boolean {
        const token = this.getToken();
        if (!token) return true;

        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor(new Date().getTime() / 1000)) >= expiry;
    }

    isLogged(): boolean {
        if (this.getToken() && !this.isTokenExpired()) {
            return true;
        }
        this.goToLogout();
        return false;
    }

    setStorageData(key: string, value: string): void {
        const expiryDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000); // Expire dans 24 heures
        this.cookieService.set(key, value, expiryDate, '/', undefined, true, 'Strict');
    }

    getStorageData(key: string): string | null {
        return this.cookieService.get(key) || null;
    }

    removeStorageData(key: string): void {
        this.cookieService.delete(key);
    }
}
